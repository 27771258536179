@use './mixins.scss' as *;

@import './calender.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100;1,200;1,300&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

:root {
  font-size: 9px;

  @include sm {
    font-size: 10px;
  }

  @include md {
    font-size: 12px;
  }

  @include lg {
    font-size: 13px;
  }

  // @include xl {
  //   font-size: 14px;
  // }

  @include xxl {
    font-size: 16px;
  }
}

th {
  font-size: 1.25rem !important;
  color: #000 !important;
  font-weight: 600 !important;
  border-bottom: 0.5px solid #b3b3b7 !important;
  padding-bottom: 1.3rem !important;
  text-transform: capitalize !important;
}

td {
  white-space: pre-wrap;
}

table {
  &.border {
    th,
    td {
      border-top: 1px solid #b3b3b7;
      border-bottom: 1px solid #b3b3b7;
      padding: 1rem;
      font-size: 1rem;
    }
    tr {
      td,
      th {
        &:not(:last-of-type) {
          border-right: 1px solid #b3b3b7;
        }
      }
    }
  }
}

[dir=rtl] {
  table {
  &.border {
    tr {
      td,
      th {
        &:not(:last-of-type) {
          border-right: none;
          border-left: 1px solid #b3b3b7;
        }
      }
    }
  }
}

}

.hide-sm {
  display: none;
  @include lg {
    display: block;
  }
}

.bg-white {
  background-color: #fff;
}

// scroll bar style
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2d5bff;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1c3eb8;
}

@media print {
  body {
    visibility: hidden;
  }
  .printable {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
}