$breakpoints: (
  'xs': 0px,
  'sm': 30em,
  'md': 48em,
  'lg': 62em,
  'xl': 80em,
  'xxl': 96em
);
$screenSizes: sm, md, lg, xl, xxl;

@mixin xs {
  @media (min-width: map-get($breakpoints, 'xs')) {
    @content;
  }
}

@mixin sm {
  @media (min-width: map-get($breakpoints, 'sm')) {
    @content;
  }
}

@mixin md {
  @media (min-width: map-get($breakpoints, 'md')) {
    @content;
  }
}

@mixin lg {
  @media (min-width: map-get($breakpoints, 'lg')) {
    @content;
  }
}

@mixin xl {
  @media (min-width: map-get($breakpoints, 'xl')) {
    @content;
  }
}
@mixin xxl {
  @media (min-width: map-get($breakpoints, 'xxl')) {
    @content;
  }
}

@mixin screen-size($size) {
  @if ($size == sm) {
    @include sm {
      @content;
    }
  } @else if ($size == md) {
    @include md {
      @content;
    }
  } @else if ($size == lg) {
    @include lg {
      @content;
    }
  } @else if ($size == xl) {
    @include xl {
      @content;
    }
  } @else if ($size == xxl) {
    @include xxl {
      @content;
    }
  }
}
