$min-width: 4rem;
$max-width: 16rem;
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: $min-width;
  background: #010e40;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);

  &::-webkit-scrollbar {
    width: 5px;
  }

  .logo {
    width: $max-width + 200;
    max-width: $max-width + '30px';
    transition: 0.5s ease;
    margin-bottom: 3rem;
  }

  .notifications {
    transition: 0.5s ease;
    left: $min-width;
  }

  &:hover {
    width: $max-width;

    .logo {
      margin-inline-start: 0;
    }

    .notifications {
      left: $max-width;
    }
  }

  .link {
    font-size: 0.875rem;
    padding-inline-start: 1.5rem;
    display: grid;
    grid-template-columns: 1.5rem auto;
    column-gap: 1rem;
    color: #fff;
    position: relative;
    white-space: nowrap;
    transition: 0.5s ease;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    cursor: pointer;
    width: 100%;

    &.active,
    &:hover {
      background-color: #001253;
      &::before {
        content: '';
        position: absolute;
        width: 0.25rem;
        top: 0;
        left: 0;
        height: 100%;
        background-color: #fff;
      }
    }
  }

  .sublink {
    font-size: 0.875rem;
    color: #fff;
    padding: 0 0.5rem;
    transition: 0.5s ease;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    cursor: pointer;
    white-space: nowrap;
    width: 100%;

    &.active,
    &:hover {
      background-color: #001253;
    }
  }
}

.active-lang {
  background-color: #001253 !important;
  color: #fff !important;
}

[dir='rtl'] {
  .sidebar {
    right: 0;

    .link {
      &.active,
      &::before {
        right: 0;
      }
    }
  }
}
